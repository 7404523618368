<template>
	<div>
		<v-alert v-if="noResults" class="mt-5" outlined color="orange">Поиск этого номера не дал результатов</v-alert>
		<v-progress-circular
			class="mt-5 mb-5"
			v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
		<v-lazy v-for="(page, i) in pages" :key="i"
			min-height="500"
		>
			<photos-page :page="page" :idx="i"></photos-page>
		</v-lazy>

	</div>
</template>

<script>
	import photosPage from './PhotosPage'
	
	// import imagesloaded from 'imagesloaded'
	const querystring = require('querystring')
  export default {
		name: 'SearchResult',
		props: [
			'eventId'
		],
		components: {
			photosPage
		},
    data(){
      return {
				pages: null,
				noResults: false,
				loading: false
      }
    },
    mounted(){
			this.getEventPhotos();
		},
    methods: {
			// num - номер участника
			getEventPhotos(num = null){
				this.pages = null;
				this.loading = true;
				const _this = this;
				this.$http.post('/api/get_event_photos.php', querystring.stringify({
					eventId: this.eventId,
					num: num
				}))
				.then(function(res){
					_this.loading = false;
					// разбиваем на страницы
					const arr = res.data.page_photos;
					let i,j,page,pages=[],chunk = 12;
					for (i=0,j=arr.length; i<j; i+=chunk) {
						page = arr.slice(i,i+chunk);
						pages.push(page);
					}
					_this.pages = pages;
					if(num && arr.length == 0){
						_this.noResults = true;
					} else {
						_this.noResults = false;
					}
				})
				.catch(function(error){
					console.log(error);
				});
			},
      search(num = null){
				this.getEventPhotos(num);
      }
    }
  }
</script>

<style>
	.v-progress-circular {
		left: 50%;
    margin-left: -35px;
	}
</style>
