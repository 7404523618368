import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main.vue'
import About from '../components/About.vue'
import Event from '../components/Event.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/about',
    name: 'about',
    component: About
	},
  {
    path: '/event/:id/:photoId?',
    name: 'event',
    component: Event,
    props: true
  }
]

const router = new VueRouter({
	mode: 'history',
	base: window.location.pathName,
  routes
})

export default router
