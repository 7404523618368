<template>

		<v-card class="found-photo">
			<a :href="item.src" data-lightbox="example-1">
				<v-img
					contain
					class="white--text img"
					:src="item.thumb_src"
				></v-img>
			</a>
			<v-card-subtitle v-if="item.nums.length">
				номера: {{item.nums.join(", ")}}
			</v-card-subtitle>
			<v-card-text class="pb-0">

			</v-card-text>
			<v-card-actions class="d-flex justify-space-between">

				<a download :href="item.src">
					<v-btn style="width: 100%;" large depressed dark color="primary">
						<span class="d-sm-none d-lg-block">Скачать</span>
						<v-icon :right="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.md">mdi-cloud-download</v-icon>
					</v-btn>

				</a>

				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn
							large depressed dark
							color="primary"
							v-on="on"
						>
							<span class="d-sm-none d-lg-block">Поделиться</span>
							<v-icon right class="m-sm-0">mdi-share</v-icon>
						</v-btn>
					</template>
					<share
						:url="this.$route.path+'?photo='+item.id"
						:img="item.thumb_src"
					></share>
				</v-menu>

			</v-card-actions>
		</v-card>

</template>

<script>
import Share from './Share';
export default {
	props: [
		'item'
	],
	data(){
		return{
			pageUrl: window.location.protocol+'//'+window.location.hostname
		}
	},
	components: {
		Share
	}
}
</script>

<style lang="scss">
	.soc_icon{
		margin-right: 10px;
		width: 30px;
		height: 30px;
		background-size: 30px 30px;
	}
	.v-list-item__title{
		display: flex;
		align-items: center;
	}
	.v-card__actions .v-btn{
		margin: 0 0!important;
	}
</style>