<template>
  <v-container>
    <v-row>
      <v-col xs="12" sm="6" md="4" xl="3" v-for="(event, i) in events.completed" :key="i">
        <card :event="event" :index="event.id"></card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Card from './Card.vue'

  export default {
    name: 'Main',
    data(){
			return {
				events: {
					completed: null
				}
			}
		},
    created(){
			this.getEvents();
		},
		methods: {
			getEvents(){
				const _this = this;
				this.$http.post('/api/getEvents.php')
				.then(function(res){
					_this.events = res.data;
				}).catch(function (error) {
					console.log(error);
				});

			}
		},
    components: {
      Card
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
