<template>

  <div>
    <v-toolbar :color="'primary'" :dark="true">
      <v-toolbar-items>
          <v-btn
            v-for="(item,index) in items"
            :key="index"
            :to="item.to"
            text
          >{{ item.name }}</v-btn>
        </v-toolbar-items>
    </v-toolbar>
  </div>

</template>

<script>
export default {
  name: 'Menu',
  props: {
  },
  data(){
    return {
      items: [
        {
          name: 'СОРЕВНОВАНИЯ',
          to: '/'
        },
        {
          name: 'О ПРОЕКТЕ',
          to: '/about'
        },
        {
          name: 'ОРГАНИЗАТОРАМ',
          to: '/organizers'
        },
        {
          name: 'ФОТОГРАФАМ',
          to: '/photographers'
        },
        {
          name: 'КОНТАКТЫ',
          to: '/contacts'
        },
        {
          name: 'БЛОГ',
          to: '/blog'
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
