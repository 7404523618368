<template>
  <div>
		
    <v-card min-height="336" v-if="eventData">
      <v-container>


      <v-row>
        <v-col class="pt-0" cols="4">
          <v-img max-height="300" v-if="eventData.cover" :src="eventData.cover"></v-img>
        </v-col>
        <v-col class="pt-0" cols="8">
          <h1>{{eventData.title}}</h1>
          <div>{{eventData.date}}</div>
          <v-radio-group v-model="searchMethod">
            <v-radio color="primary" label="Поиск по номеру" value="number"></v-radio>
            <v-radio color="primary" label="Поиск по лицу" value="face"></v-radio>
          </v-radio-group>
              <div v-show="searchMethod == 'number'">
                  <v-text-field
										style="width: 236px"
										v-model="number"
										label="Ваш номер"
										ref="input"
										outlined
										:append-icon="'mdi-magnify'"
										@click:append="newSearch"
										@keydown.enter="newSearch"
                  > </v-text-field>
              </div>
          <upload-btn @file-update="newSearch" class="pl-0" v-if="searchMethod == 'face'" large title="Загрузите селфи" color="primary"
                      style="padding-top: 9px;">
            <template slot=icon-left>
              <v-icon>mdi-cloud-upload</v-icon>
            </template>
          </upload-btn>
        </v-col>
      </v-row>
      </v-container>
    </v-card>
    <search-result :eventId="id" ref="searchRes"></search-result>
  </div>
</template>

<script>
// import events from '../events.json'
import SearchResult from './SearchResult.vue'
import UploadButton from 'vuetify-upload-button'
const querystring = require('querystring');

export default {
  name: 'Event',
  props: [
    'id',
		'event',
		'photo'
  ],
  components: {
    SearchResult,
    'upload-btn': UploadButton
  },
  data(){
    return {
      number: null,
			searchMethod: 'number',
			eventData: null
    }
	},
  created(){
		this.getEvent();
		const photo = window.photoSrc;
		if(photo){
			this.showPhoto('/'+photo);
		}
  },
  mounted(){
		this.focus()
		// console.log(UploadButton)
  },
  watch: {
    searchMethod: function(val){
      if(val == 'number'){
        this.focus()
      }
    }
  },
  methods:{
		getEvent(){
			const _this = this;
			this.$http.post('/api/get_event.php', querystring.stringify({ event: this.id }))
			.then(function(res){
				_this.eventData = res.data;
			})
			.catch(function(err){
				console.log(err);
			});
		},
    focus(){
      // const _this = this;
      setTimeout(function(){
        // _this.$refs.input.focus()
      }, 0);
    },
    newSearch(){
      this.$refs.searchRes.search(this.number)
		},
		// если есть ссылка на конкретное фото, например была расшарена ссылка для vk, то увеличиваем это фото сразу
		showPhoto(photo){
			const a = document.createElement('a');
			a.setAttribute('href', photo);
			a.setAttribute('data-lightbox', 'firstload');
			document.body.appendChild(a);
			window.$(document).ready(function(){
				a.click();
			});
		}
  }
}
</script>

<style>
  label.upload-btn{
    padding-top: 9px;
    padding-bottom: 5px;
  }
  label.upload-btn .v-icon{
    margin-right: 6px;
  }
</style>