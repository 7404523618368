import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios/dist/axios.min'
import lightbox from 'lightbox2/dist/js/lightbox-plus-jquery.min'
import 'lightbox2/dist/css/lightbox.min.css'
import '../scss/style.scss'
import jquery from 'jquery/dist/jquery.min'

window.$ = jquery;

lightbox.option({
	'showImageNumberLabel': false
})

Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
	vuetify,
	axios,
  render: h => h(App)
}).$mount('#app')
