
// получаем родительский компонент по его имени с любого уровня глубины
function getComponent(componentName){
	let component = null
	let parent = this.$parent
	while (parent && !component) {
		if (parent.$options.name === componentName) {
			component = parent
		}
		parent = parent.$parent
	}
	return component
}

export {
	getComponent
}