<template>
	<v-list>
		<v-list-item
			v-for="(socParam, i) in socParams"
			:key="i"
			@click="share(socParam)"
		>
			<v-list-item-title>
				<div class="soc_icon" :style="'background-image: url(/img/'+socParam.icon+')'"></div>{{socParam.name}}
			</v-list-item-title>
		</v-list-item>
	</v-list>
</template>

<script>
import {getComponent} from '../helpers.js'

export default {
	props: [
		'url',
		'img',
		'title'
	],
	data(){
		return{
			eventTitle: null,
			desc: 'Сервис поиска ваших фотографий с мероприятий по лицу или номеру на основе компьютерного зрения.',
			socParams: [
				{
					name: 'Вконтакте',
					icon: 'vk.svg',
					link: 'https://vk.com/share.php',
					paramsNames: {
						url: 'url',
						title: 'title',
						img: 'image',
						desc: 'description'
					}
				},
				{
					name: 'Facebook',
					icon: 'fb.svg',
					link: 'https://www.facebook.com/sharer.php',
					paramsNames: {
						url: 'u',
						title: 'title',
						img: 'image',
						desc: 'description'
					},
					additionalParams: {
						src: 'sp'
					}
				},
				{
					name: 'Одноклассники',
					icon: 'ok.svg',
					link: 'https://connect.ok.ru/offer',
					paramsNames: {
						url: 'url',
						title: 'title',
						img: 'image',
						desc: 'description'
					}
				}
			]
		}
	},
	computed: {
		titleSum: function(){
			return 'asdf'
		}
	},
	methods: {
		share(socParams){

			const siteUrl = window.location.protocol+'//'+window.location.hostname

			// основная часть
			const base = socParams.link

			// параметр url
			const urlParam = socParams.paramsNames.url+'='+siteUrl+this.url

			// параметр title
			let title;

			if(!this.title){
				const getComponentByName = getComponent.bind(this);
				title = getComponentByName('Event').eventData.title;
			} else {
				title = this.title;
			}


			const titleParam = socParams.paramsNames.title+'='+title + ' | Найти фотографии с мероприятия'

			// параметр description
			const descParam = socParams.paramsNames.desc+'='+this.desc

			// параметр img
			const imgParam = socParams.paramsNames.img+'='+siteUrl+this.img;

			// итого

			let full_url;
			if(socParams.name != 'Facebook'){
				full_url = base+'?'+urlParam+'&'+imgParam+'&'+titleParam+'&'+descParam
			} else {
				full_url = base+'?'+urlParam
			}

			const encoded_url = encodeURI(full_url);
			window.open(encoded_url, 'targetWindow',
				`toolbar=no,
					location=no,
					status=no,
					menubar=no,
					scrollbars=yes,
					resizable=yes`)
		}
	}
}
</script>

<style>
	.soc_icon{
		margin-right: 10px;
		width: 30px;
		height: 30px;
		background-size: 30px 30px;
	}
</style>