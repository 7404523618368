<template>
	<v-row class="page" :data-id="idx">
		<v-col xs="12" sm="6" md="4" xl="3" v-for="(item, index) in page"
				:key="index">
			
			<found-photo
				:item="item"
				
			></found-photo>
					
		</v-col>
	</v-row>
</template>

<script>
	import foundPhoto from './FoundPhoto'
	export default {
		props: [
			'page',
			'idx'
		],
		components: {
			foundPhoto
		},
		mounted(){
			this.animateIn()
		},
		methods: {
			animateIn(){
				const _this = this;
        // imagesloaded( '.found-photo img', function() {
					// console.log('Изображения загружены')
					setTimeout(function(){

						var tl = new window.TimelineLite()
						tl.staggerTo('.page[data-id='+_this.idx+'] .found-photo', 1, {opacity: 1,scaleX:1,scaleY:1, ease: window.Circ.easeOut}, 0.1)
					}, 500)
        // })
			},
		}
	}
</script>

<style>
	.found-photo{
    opacity: 0;
    transform: scale(0.5);
  }
</style>