<template>
  <v-card
    class="mx-auto"
    max-width="400"
  >
		<router-link :to="'/event/'+index">
			<v-img
				class="white--text align-end"
				height="200px" 
				:src="event.cover"
			>
				<v-card-title>{{event.title}}</v-card-title>
			</v-img>
		</router-link>

    <v-card-subtitle class="pb-0">{{event.date}}</v-card-subtitle>

    <v-card-text class="text--primary">
      <div>{{event.title}}</div>
    </v-card-text>

    <v-card-actions>
      <v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn
						color="primary"
						v-on="on"
						text
					>
						<span class="d-sm-none d-lg-block">Поделиться</span>
						<v-icon right class="m-sm-0">mdi-share</v-icon>
					</v-btn>
				</template>
				<share
					:url="'/event/'+index"
					:img="event.cover"
					:title="event.title"
				></share>
			</v-menu>

      <v-btn
        :to="'/event/'+index"
        color="primary"
        text
      >
        Смотреть фото
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Share from './Share';
export default {
  name: 'Card',
  props: [
    'event', 'index'
	],
	components: {
		Share
	}
}
</script>

<style>
  .v-card__title{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .v-card__text{
    padding-bottom: 0;
    margin-top: 10px;
  }
	.v-card__actions .v-btn{
		width: 50%;
    text-align: center;
		margin: 0 auto!important;
	}
	.v-card__actions .v-btn span{
		white-space: pre-line!important;
		width: 100%;
	}
</style>