<template>

    <v-app id="inspire">
      <Menu></Menu>
      <v-container class="content">
        <router-view></router-view>
      </v-container>
      <v-footer
        absolute
        class="font-weight-medium"
        color="grey darken-3"
        :dark="true"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>photoget.ru</strong>
        </v-col>
      </v-footer>
    </v-app>
    
</template>

<script>
  import Menu from './components/Menu.vue'
  export default {
    name: 'app',
    components: {
      Menu
    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
	.content{
		padding-bottom: 100px!important;
	}
</style>
